@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);

blockquote {
    font-size: 1.4em;
    margin: 50px auto;
    font-family: Open Sans;
    font-style: italic;
    padding: 1.2em 30px 1.2em 75px;
    border-left: 8px solid #78C0A8;
    line-height: 1.6;
    position: relative;
}

blockquote::before {
    font-family: Arial;
    content: "\201C";
    color: #78C0A8;
    font-size: 4em;
    position: absolute;
    left: 10px;
    top: -10px;
}

blockquote::after {
    content: '';
}

blockquote span {
    display: block;
    font-style: normal;
    font-weight: bold;
    margin-top: 1em;
}
@charset "UTF-8";

/* CSS Document */
html {
  /* font-size: 62.5%; */
  /* 16px * 62.5% =10px */
  width: 100%;
  box-sizing: border-box;
}

body {
  color: #333;
  /* RGB */
  font-family: "游ゴシック体", YuGothic, Arial, "sans-serif" "Yu Gothic medium",
    "Hiragino Sans", Meiryo, "sans-serif";
  /* font-weight: 500;
  font-size: 1.6em;
  line-height: 2.4rem; */
}

/*
section h2 {
  font-size: 2.4rem;
}

a:hover {
  opacity: 0.5;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}